var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide"}},[_c('div',{staticClass:"absolute h-screen flex",class:{
            '-left-full': _vm.showCharacter,
            'left-0': !_vm.showCharacter,
        },attrs:{"id":"cont"}},[_c('div',{staticClass:"h-full w-screen overflow-auto"},_vm._l((_vm.alphabet),function(character){return _c('router-link',{key:character.id,staticClass:"h-20 w-full grid grid-cols-4 border-b border-gray-100",class:{
                    'text-indigo-500': character.vowel,
                    'text-gray-600': !character.vowel,
                },attrs:{"to":{
                    name: 'Alphabet',
                    params: { letterId: character.id },
                }}},[_c('div',{staticClass:"flex items-center justify-center text-4xl"},[_vm._v(" "+_vm._s(character.variants.isolated)+" ")]),_c('div',{staticClass:"flex items-center justify-center text-xl"},[_vm._v(" "+_vm._s(character.name)+" ")]),_c('div',{staticClass:"flex items-center justify-center text-xl"},[_vm._v(" "+_vm._s(character.latinSymbol)+" ")]),_c('div',{staticClass:"flex items-center justify-center text-xl"},[_vm._v(" "+_vm._s(character.ipa)+" ")])])}),1),_c('div',{staticClass:"h-full w-screen"},[_c('character',{attrs:{"id":_vm.oldCharacter}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }